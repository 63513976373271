<template>
  <div>
    <section class="w3l-timeline-1 text-center py-5">
        <div class="container py-lg-5 py-4">
            <div class="mx-auto" style="max-width:800px">
                <h3 class="title-style mb-4">Why hire me for your next project?</h3>
                <p>I love to work in User Experience & User Interface designing. Because I love to solve the design
                    problem
                    and find easy and better solutions to solve it. I always try my best to make good user interface
                    with
                    the best user experience.</p>
                <router-link :to="{name:'Services'}" class="btn btn-style mt-5">Learn More</router-link>
            </div>
        </div>
    </section>

    <section class="w3l-about2 py-5">
        <div class="container py-lg-5 py-md-4 py-2">
            <div class="row align-items-center">
                <div class="col-lg-6 pe-lg-5">
                    <h5 class="title-small mb-1">Web Design</h5>
                    <h3 class="title-style">I Would Love to make your Ideas real</h3>
                    <div class="cwp23-text-cols mt-lg-5 mt-4">
                        <div class="column">
                            <span>15+</span>
                            <h4>Happy Clients</h4>
                            <p>We help our clients increase profits by increasing their visibility online.</p>
                        </div>
                        <div class="column">
                            <span>27+</span>
                            <h4>Completed projects </h4>
                            <p>We help our clients increase profits by increasing their visibility online. </p>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6 cwp23-text align-self mt-lg-0 mt-5">
                    <img src="../assets/images/blog5.jpg" alt="" class="radius-image img-fluid">
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutMe',
  data () {
      return {
          
      }
    },
    created () {
      
    },
    methods:{
        

            
    },
        
       
       
        
}
  

</script>

<style scoped>
.router-link-active, .router-link-exact-active{
 color: #f54748 !important;
}
</style>
