<template>
  <div class="">
    <inner-banner title="About Me" />
    <about-section :show="false" />
    <about-me />
    <resume-section />
    <skill-section />
    <portfolio-section />
    <Footer />
  </div>
</template>

<script>
import AboutSection from '../components/AboutSection.vue'
import ResumeSection from '../components/ResumeSection.vue'
import SkillSection from '../components/SkillSection.vue'
import PortfolioSection from '../components/PortfolioSection.vue'
import Footer from '../components/Footer.vue'
import InnerBanner from '../components/InnerBanner.vue'
import AboutMe from '../components/AboutMe.vue'

export default {
  name: 'About',
  components: {
    AboutSection,
    ResumeSection,
    SkillSection,
    PortfolioSection,

    Footer,
    InnerBanner,
    AboutMe
  }
}
</script>
